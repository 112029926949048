export enum PaymentSummaryDataHooks {
  PAYMENT_SUMMARY = 'payment-summary',
  TITLE = 'payment-summary-title',
  CUSTOM_PRICE = 'payment-custom-price',
  TOTAL_PRICE = 'payment-total-price',
  SUMMARY_ITEM = 'payment-summary-item',
  SUMMARY_ITEM_LABEL = 'payment-summary-label',
  SUMMARY_ITEM_DESCRIPTION = 'payment-summary-description',
  SPINNER = 'payment-summary-spinner',
}

import React, { FC } from 'react';
import Text, { TextType } from '../../../Text/Text';
import { TagName } from '../../../../../utils/tagName.const';
import { classes } from './PriceSummaryItem.st.css';
import { PriceSummaryDataHooks } from './dataHooks';

export type PriceSummaryItemProps = {
  type: TextType;
  label: string;
  description: string;
};

export const PriceSummaryItem: FC<PriceSummaryItemProps> = ({
  type,
  label,
  description,
}) => {
  return (
    <div className={classes.root} data-hook={PriceSummaryDataHooks.ITEM}>
      <Text
        type={type}
        tagName={TagName.Span}
        data-hook={PriceSummaryDataHooks.LABEL}
      >
        {label}
      </Text>
      <Text
        type={type}
        data-hook={PriceSummaryDataHooks.DESCRIPTION}
        tagName={TagName.Span}
      >
        {description}
      </Text>
    </div>
  );
};
